export const FirebaseConfig = {
	"projectId": "taxii-e9222",
	"appId": "1:655299751162:web:48b4f1d365d1b2825cad43",
	"databaseURL": "https://taxii-e9222-default-rtdb.firebaseio.com",
	"storageBucket": "taxii-e9222.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyA_vv--WQUFkVj7o9p22UnF8x0EJ6RXuFA",
	"authDomain": "taxii-e9222.firebaseapp.com",
	"messagingSenderId": "655299751162",
	"measurementId": "G-ZZ3GJ8KBMK"
};